import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typescript"
    }}>{`TypeScript`}</h1>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`rework.js treats TypeScript files like other files. While tsc won't be used, you can use the built-in babel integration
to compile typescript files by adding the `}<inlineCode parentName="p">{`@babel/preset-typescript`}</inlineCode>{` package to `}<inlineCode parentName="p">{`babel.config.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// babel.config.js

module.exports = {
  presets: [
    ['@reworkjs/core/babel-preset'],
    ['@babel/preset-typescript', {
      isTSX: true,
      allExtensions: true,
    }],
  ],
};
`}</code></pre>
    <h2 {...{
      "id": "typechecking"
    }}>{`Typechecking`}</h2>
    <p>{`Babel will not check whether the typing is correct when building, but you can use tsc to typecheck
with this command: `}<inlineCode parentName="p">{`tsc --noEmit --project tsconfig.json --skipLibCheck`}</inlineCode>{`.
You will need to install TypeScript (`}<inlineCode parentName="p">{`npm i -D typescript`}</inlineCode>{`)`}</p>
    <p>{`You will also need to configure tsc through tsconfig:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json5"
      }}>{`// tsconfig.json

{
  "compilerOptions": {
    "module": "commonjs",
    "target": "es6",
    "jsx": "react",
    "strict": true,
    "moduleResolution": "node",
    "sourceMap": true,
    "rootDir": "src",
    "noEmit": true,
    "baseUrl": ".",
    "paths": {
      "*": [
        "node_modules/*",
        "types/*"
      ]
    }
  },
  "include": [
    "src/**/*"
  ]
}
`}</code></pre>
    <p>{`rework.js uses a number of libraries with which you will interact. Please install their typing to avoid any typing error:`}</p>
    <p><inlineCode parentName="p">{`npm i -D @types/loadable__component`}</inlineCode></p>
    <h3 {...{
      "id": "css-typechecking"
    }}>{`CSS TypeChecking`}</h3>
    <p>{`Because we import css files as if it were JavaScript, we need to tell TypeScript how to interpret it.
Simply adding the following file in your project will do it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// style.d.ts

// css & css modules
declare module '*.css' {
  const classes: { [key: string]: string };
  export default classes;
}

// scss & scss modules
declare module '*.scss' {
  const classes: { [key: string]: string };
  export default classes;
}
`}</code></pre>
    <p>{`Alternatively tools exist to generate these typings for you, such as `}<a parentName="p" {...{
        "href": "https://github.com/skovy/typed-scss-modules"
      }}>{`typed-scss-modules`}</a></p>
    <h2 {...{
      "id": "linting"
    }}>{`Linting`}</h2>
    <p>{`You will need to adapt your eslint config to support typescript.
Don't forget to update `}<inlineCode parentName="p">{`.lintstagedrc`}</inlineCode>{` to lint typescript files during commit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      